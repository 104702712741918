<template>
  <div>
  <CCard>
   
  <CCardBody>
    <div class="clearfix">
      <button type="button" class="btn btn-primary float-xl-right" @click="navToNewSymptoms">New User</button>
    </div>

    <CDataTable :items="items"  
    :fields="fields"
    striped
      column-filter
       table-filter
        items-per-page-select
      :items-per-page="20"
       sorter
       ref="vuetable"
      pagination>
       
      <template #index="data">
       <td>
         {{data.index +1}}
         </td>
     </template>

<template #designation="{item}">
        <td class='font-weight-bold'
          v-if="item.designation"
        >{{ item.designation  }}</td>
        <td v-else>--</td>
      </template>
      <template #createdTime="{item}">
        <td class='font-weight-bold'
          v-if="item.createdTime"
        >{{ item.createdTime |dateParse()| dateFormat("MMM D, YYYY hh:mm A") }}</td>
        <td v-else>--</td>
      </template>
     
     
       <template #show_details="{item}">
        <td class="py-2">
        <CDropdown
                  togglerText="Actions"
                  color="info"
                >
                  <CDropdownItem :to="{name: 'Department User', params: {uid: item.uid,departmentId: item.departmentId, mode: 'view'}}">View </CDropdownItem>
               <CDropdownItem :to="{name: 'Skills', params: {ic_n: item.ic_n, mode: 'view'}}">Skills </CDropdownItem>
                <CDropdownItem :to="{name: 'EdrsAvability', params: {ic_n: item.ic_n , mode: 'view'}}">Employee Avability</CDropdownItem>
            
               <!-- <CDropdownItem :to="{name: 'JobTypes', params: {departmentId: item.departmentId, mode: 'view'}}">Job Types </CDropdownItem>
                <CDropdownItem :to="{name: 'Department Users', params: {departmentId: item.departmentId, mode: 'view'}}">Department Users </CDropdownItem> -->
                <ConfirmationModal
                      :parent="$refs.modalArea"
                      :description="'Are you sure to Delete'"
                      @on:ok="deleteUser(item.uid)"
                    ></ConfirmationModal>

                </CDropdown>

        
        </td>
      </template>
      </CDataTable>
  </CCardBody>
  <div ref="modalArea"></div>
  <CCardFooter align="right">
          
            <CButton
              class="mr-2"
              type="Cancel"
              size="sm"
              color="danger"
              @click="cancel()"
            >
              <CIcon name="cil-ban" />Cancel
            </CButton>
          </CCardFooter>
 </CCard>
 </div>
</template>

<script>
const fields = [
  'index',
  { key: "firstName", _classes: 'font-weight-bold',  _style: "min-width:200px" },
  { key: "lastName", _classes: 'font-weight-bold', _style: "min-width:200px" },
   { key:  "mobile", _classes: 'font-weight-bold', _style: "min-width:200px" },
   { key:  "ic_n",label: "ic_n", _classes: 'font-weight-bold', _style: "min-width:200px" },
    { key:"email", _classes: 'font-weight-bold', _style: "min-width:200px" },
     { key:"authority",label: "Role", _classes: 'font-weight-bold', _style: "min-width:200px" },
 { key:"designation",label: "Designation", _classes: 'font-weight-bold', _style: "min-width:200px" },

  { key: "createdTime",label: "Created Date & Time", _style: "min-width:200px" },
   { key: "show_details",
    label: "",
    _style: "width:1%",
    sorter: false,
    filter: false,
  },
  
];
import ConfirmationModal from "../../containers/ConfirmationModal";
export default {
  name: "Department Users",
   components: {
    ConfirmationModal,
   },
  data() {
    return {
      items: [],
      fields,
      details: [],
      collapseDuration: 0,
    };
  },
  methods: {
    getDepartments() {
     this.apiGetSecure(process.env.VUE_APP_API_HOST + "/emapp/web/secure/get/users/"+this.$route.params.departmentId)
        .then((response) => response.json())
        .then((data) => (this.items = data));
    },
     cancel() {
      window.history.back();
    },
    
    deleteUser(uid) {
      console.log("delete called with", this.uid);
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST +"/emapp/web/secure/delete/user/" + uid
      )
        .then((response) => response.json())
        .then((data) => {
          this.getDepartments();
             this.$nextTick(() => {
             this.$refs.vuetable.refresh();
           });
           window.location.reload();
        });
    },
    navToNewSymptoms(){
       this.$router.push({ name: "Department User", params: {departmentId: this.$route.params.departmentId, mode: "new" } });
    }
  },
  mounted() {
    this.getDepartments();
  },
};
</script>